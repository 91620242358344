.video {
  width: 100%;
}

.content-alignment {
  white-space: pre-line;
}

.modal-Content {
  position: relative;
  padding-bottom: 58.25%;
  height: 0px;
}

.video-content {
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
}

.tds-button-textContent {
  margin-right: 0.5rem;
}

.tds-button-icon {
  margin-bottom: 0.625rem;
}

@media screen and (max-width: 26.5rem) {
  .trv-logo {
    margin-left: 0rem;
  }
  .tds-button-icon {
    margin-left: 11rem;
  }
}

// Imports the TDS Core Development Files
@import '~@trv-tds/core/dist/sass/tds.scss';
@import '~@trv-tds/core/dist/sass/utilities.scss';

// Sets up mixins and color calls
@import '~@trv-tds/core/dist/sass/tokens/_tokens.scss';
@import '~@trv-tds/core/dist/sass/_allmixins.scss';

.boat-details-banner {
    background: linear-gradient(0deg, #F2F8F9 50%, #FFFFFF 117%);
    padding: $s-x-large 0 $s-xxx-large;
    position: relative;

    .boat-details-banner-content {
        z-index: 4;
        position: relative;
    }

    .mountains {
        position: absolute;
        left: 0px;
        bottom: 9px;
        background-image: url('../../../../assets/boat-images/mountains.svg');
        background-repeat: repeat-x;
        background-position: bottom center;
        background-size: contain;
        width: 100%;
        height: 159px;
        z-index: 1;
    }

    .boat {
        position: absolute;
        z-index: 2;
        bottom: 10px;

        @include media-breakpoint-up(xs) {
            right: 20px;
            display: inline-block;

            .boat-illustration {
                width: 130px;
            }
        }

        @include media-breakpoint-up(sm) {
            right: 30px;
            display: inline-block;

            .boat-illustration {
                width: 150px;
            }
        }

        @include media-breakpoint-up(md) {
            right: 50px;
            display: inline-block;

            .boat-illustration {
                width: 150px;
            }
        }

        @include media-breakpoint-up(xl) {
            right: 130px;

            .boat-illustration {
                width: 250px;
            }
        }
    }

    .bottom-waves {
        position: absolute;
        left: 0px;
        bottom: 0px;
        background-image: url('../../../../assets/boat-images/waves.svg');
        width: 100%;
        height: 41px;
        background-repeat: repeat-x;
        background-position: bottom center;
        z-index: 3;
    }
}

.boat-details-banner-heading {
    color: $c-accent-blueberry;
}

.boat-details-banner-agent-heading {
    color: $c-accent;
}

.boat-details-date-card {
    padding: 0.3rem 0.5rem 0.20rem 0.5rem;
    width: fit-content;
    align-items: center;
    background: #F6F6F6;
    box-shadow: 0px 1px 2px rgba(51, 93, 121, 0.07), 0px 2px 4px rgba(51, 93, 121, 0.07), 0px 4px 8px rgba(51, 93, 121, 0.07);
    border-radius: 6.25rem;
}

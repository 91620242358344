.logo-header-text {
  text-decoration: none;
}
.logo-header-text:hover {
  background-color: transparent;
}
.logo-header-text:focus {
  background-color: transparent;
}

